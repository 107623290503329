import React from 'react'
import { useQuery } from '@apollo/client'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { LinkButton } from '@/atoms/Button'
import { LivestreamIcon } from '@/atoms/Icons/LivestreamIcon'
import { PlayCircleIcon } from '@/atoms/Icons/PlayCircleIcon'
import { Image } from '@/atoms/Image'
import { AsFigCaption, AsH2, HeadingXS, LabelMD, ParagraphMD } from '@/atoms/Text'
import { paths } from '@/constants'
import { getCurrentLivestreamQuery } from '@/services/ProjectsService/queries'
import { FilmDetails as FilmDetailsType } from '@/types/codegen-contentful'
import { useTranslate } from '@/utils/translate/translate-client'

export interface LivestreamLinkProps {
  filmDetails?: FilmDetailsType
  projectName: string
  className?: string
  projectId?: number | null
}

export const LivestreamLink: React.FC<LivestreamLinkProps> = ({ filmDetails, projectName, className, projectId }) => {
  const { data: currentLivestreamData } = useQuery(getCurrentLivestreamQuery, {
    variables: { projectId: projectId, skip: !projectId },
    errorPolicy: 'all',
  })
  const { t } = useTranslate('theatrical-presales')
  const { push } = useRouter()

  if (!filmDetails) return null

  const { livestreamImageUrl, livestreamDescription, livestreamButtonText, livestreamButtonHref } = filmDetails
  const currentLivestream = currentLivestreamData?.currentLivestream
  const showWatch = currentLivestream?.live || currentLivestream?.isEnded
  const currentLivestreamButtonText = showWatch ? t('watchNow', 'Watch Now') : t('rsvpNow', 'RSVP Now')
  const showLivestreamLink =
    (livestreamImageUrl && livestreamDescription && livestreamButtonText && livestreamButtonHref) || currentLivestream
  const livestreamHref = currentLivestream
    ? `${paths.livestream.index}/${currentLivestream.guid}`
    : livestreamButtonHref ?? ''

  return (
    showLivestreamLink && (
      <div className="pt-8 lg:pt-16">
        <figure
          className={classNames(
            'flex flex-col gap-6 bg-black p-8 pt-16 text-center text-white sm:px-8 sm:py-16 md:flex-row md:justify-center md:gap-12 md:px-16 lg:gap-20 2xl:gap-28',
            className,
          )}
        >
          <div className="relative">
            <Image
              className="max-w-full cursor-pointer rounded-2xl shadow-dark-5 duration-[400ms] ease-in-out hover:opacity-85 md:mx-0 md:max-h-[210px] md:max-w-fit lg:max-h-[260px] xl:max-h-[360px] "
              alt={t('altTextForCabriniLivestream', '{{ projectName }} Livestream', { projectName })}
              src={currentLivestream?.bannerCloudinaryPath ?? livestreamImageUrl}
              width={639}
              height={360}
              onClick={() => push(livestreamHref)}
            />
            <PlayCircleIcon className="absolute left-1/2 top-1/2 z-10 h-12 w-12 -translate-x-1/2 -translate-y-1/2 rounded-full bg-core-gray-950/70" />
          </div>
          <div className="flex flex-col items-start justify-center gap-2 text-left">
            <LivestreamIcon color={'white'} size={40} />
            <HeadingXS
              as={AsH2}
              id="livestream-section-title"
              className="photon-title-md md:photon-title-lg lg:photon-heading-xs max-w-[288px]"
              weight="bold"
            >
              {t('watchProjectExclusiveLivestream', 'Watch {{ projectName }} Exclusive Livestream', { projectName })}
            </HeadingXS>
            <ParagraphMD
              as={AsFigCaption}
              className="photon-caption-sm lg:photon-paragraph-md weight-[350] max-w-[341px] text-core-gray-300"
            >
              {currentLivestream?.description.replace(/([.?!])\s*(?=[A-Z])/g, '$1|').split('|')[0] ??
                livestreamDescription}
            </ParagraphMD>
            <LinkButton
              className="mt-2 w-fit px-8 py-4 text-core-gray-950 md:px-8 lg:px-16"
              href={livestreamHref}
              variant="white"
            >
              <LabelMD weight="bold">
                {currentLivestream ? `${currentLivestreamButtonText}!` : livestreamButtonText}
              </LabelMD>
            </LinkButton>
          </div>
        </figure>
      </div>
    )
  )
}
